import { createI18n } from "vue-i18n";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
 const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
 const messages = {};
 locales.keys().forEach((key) => {
  const matched = key.match(/([A-Za-z0-9-_]+)\./i);
  if (matched && matched.length > 1) {
   const locale = matched[1];
   messages[locale] = locales(key).default;
  }
 });
 return messages;
}
const datetimeFormats = {
 en: {
  hour: {
   hour: "2-digit",
   minute: "numeric",
   hour12: true,
  },
  shortNumb: {
   year: "numeric",
   month: "numeric",
   day: "2-digit",
  },
  shortText: {
   year: "numeric",
   month: "short",
   day: "numeric",
  },
  longNumb: {
   year: "numeric",
   month: "short",
   day: "numeric",
   hour: "2-digit",
   minute: "numeric",
   hour12: true,
  },
  longText: {
   year: "numeric",
   month: "short",
   day: "numeric",
   weekday: "short",
   hour: "numeric",
   minute: "numeric",
   hour12: true,
  },
 },
 fr: {
  hour: {
   hour: "numeric",
   minute: "numeric",
   hour12: false,
  },
  shortNumb: {
   year: "numeric",
   month: "numeric",
   day: "numeric",
  },
  shortText: {
   year: "numeric",
   month: "short",
   day: "numeric",
  },
  longNumb: {
   year: "numeric",
   month: "short",
   day: "numeric",
   hour: "2-digit",
   minute: "numeric",
   hour12: false,
  },
  longText: {
   year: "numeric",
   month: "short",
   day: "numeric",
   weekday: "short",
   hour: "numeric",
   minute: "numeric",
   hour12: false,
  },
 },
};
const numberFormats = {
 en: {
  currency: {
   style: "currency",
   currency: "EUR",
   notation: "standard",
  },
  decimal: {
   style: "decimal",
   minimumFractionDigits: 2,
   maximumFractionDigits: 2,
  },
  percent: {
   style: "percent",
   useGrouping: false,
  },
 },
 fr: {
  currency: {
   style: "currency",
   currency: "EUR",
   useGrouping: true,
   currencyDisplay: "symbol",
  },
  decimal: {
   style: "decimal",
   minimumSignificantDigits: 3,
   maximumSignificantDigits: 5,
  },
  percent: {
   style: "percent",
   useGrouping: false,
  },
 },
};
export default createI18n({
 locale: localStorage.getItem("language") || process.env.VUE_APP_I18N_LOCALE || "en",
 fallbackLocale:
  localStorage.getItem("language") || process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
 messages: loadLocaleMessages(),
 datetimeFormats: datetimeFormats,
 numberFormats: numberFormats,
});
