<template>
 <div class="relative bg-gray-100 flex min_height_app">
  <Sidebar
   @closeTicketDetail="closeTicketDetail = !closeTicketDetail"
   @deactiveHover="sideNavHover = false"
   @openSideNavHover="openSideNavHover($event)"
   :navigation="navigationLong"
   :secondaryNavigation="secondaryNavigation"
   :specialNavigation="updatedSpecialNavigation"
   :showSpecialNavigation="showSpecialNavigation"
   :statisticsAvailableForProduction="statisticsAvailableForProduction"
   v-if="
    this.$route.path !== '/login' &&
    this.$route.name !== 'TrainingQuizDetail' &&
    this.$route.name !== 'ResetPassword' &&
    this.$route.name !== 'API404' &&
    this.$route.name !== '404'
   "
   @openNewSidebar="openSide = !openSide"
   @open-feedback="openFeedback = $event"
   @closeSubmenu="openSubmenu = false"
   :openSubmenu="openSubmenu"
   :userPermissions="userPermissions.permissions || []"
  />

  <div class="flex-1 flex flex-col overflow-hidden">
   <AccouncementBanner />
   <MainHeader
    v-if="
     this.$route.path !== '/login' &&
     this.$route.name !== 'TrainingQuizDetail' &&
     this.$route.name !== 'ResetPassword' &&
     this.$route.name !== 'API404' &&
     this.$route.name !== '404'
    "
    @openSubmenu="openSubmenu = true"
    @activeBanner="(activeBanner = true), (key = !key)"
    @successMsg="successMsg = $event"
    @success="isSuccess = $event"
    @close-feedback="openFeedback = $event"
    v-model:search="search"
    :openFeedback="openFeedback"
   />
   <div class="flex-1 flex items-stretch overflow-hidden">
    <aside
     v-show="
      showSideNav &&
      this.$route.name !== 'Login' &&
      this.$route.name !== 'TrainingQuizDetail' &&
      this.$route.name !== 'ResetPassword' &&
      this.$route.name !== '404' &&
      this.$route.name !== 'Error' &&
      this.$route.name !== 'API404' &&
      this.$route.name !== 'GetStarted' &&
      this.$route.name !== 'Unauthorized' &&
      this.$route.name !== 'EnableService' &&
      this.$route.name !== 'SMS' &&
      hasSideNav &&
      sideNavHover &&
      !hideSideNav
     "
     class="hidden side_nav_width navbar border-gray-100 overflow-y-auto lg:block border-r border-t border-gray-300"
    >
     <SideNav
      @closeSideNavHover="sideNavHover = false"
      @deactiveHover="sideNavHover = false"
      :isHover="true"
      @hasNavigation="hasSideNav = $event"
      @showSideNav="showSideNav = false"
      :navigation="
       sideHoverNav === 'sidebar.statistics'
        ? statisticsNavigation
        : sideHoverNav === `Expert Stats`
        ? expertStatisticsNavigation
        : sideHoverNav === 'sidebar.specialNavigation.smartRoutings'
        ? expertNavigation
        : sideHoverNav === 'SMS'
        ? smsNavigation
        : sideHoverNav === 'sidebar.tickets.tickets'
        ? ticketNavigation
        : sideHoverNav === 'Training'
        ? trainingNavigation
        : ''
      "
     />
    </aside>
    <aside
     v-show="
      this.$route.name !== 'Login' &&
      this.$route.name !== 'TrainingQuizDetail' &&
      this.$route.name !== 'ResetPassword' &&
      this.$route.name !== '404' &&
      this.$route.name !== 'Error' &&
      this.$route.name !== 'API404' &&
      this.$route.name !== 'GetStarted' &&
      this.$route.name !== 'Unauthorized' &&
      this.$route.name !== 'CustomerContacts' &&
      this.$route.name !== 'CustomerContactDetail' &&
      this.$route.name !== 'EnableService' &&
      this.$route.name !== 'SMS' &&
      !this.$route.path.includes('invoices') &&
      !this.$route.path.includes('documents') &&
      !this.$route.path.includes('profile') &&
      !this.$route.path.includes('changelog') &&
      !this.$route.path.includes('customer-files') &&
      !this.$route.path.includes('web-radios') &&
      !sideNavHover &&
      showSideNav &&
      !hideSideNav
     "
     class="hidden side_nav_width navbar border-gray-100 overflow-y-auto lg:block border-r border-t border-gray-300"
    >
     <SideNav
      @hasNavigation="hasSideNav = $event"
      @deactiveHover="sideNavHover = false"
      @showSideNav="showSideNav = false"
      v-if="this.$route.name"
      :navigation="
       this.$route.name.includes('PBX')
        ? statisticsNavigation
        : this.$route.name.includes('ExpertStatistics')
        ? expertStatisticsNavigation
        : this.$route.name.includes('Expert3cx')
        ? expertNavigation
        : this.$route.path.includes('sms')
        ? smsNavigation
        : this.$route.path.includes('ticket')
        ? ticketNavigation
        : this.$route.path.includes('training')
        ? trainingNavigation
        : ''
      "
     />
    </aside>
    <aside
     v-show="
      !showSideNav &&
      this.$route.name !== 'Login' &&
      this.$route.name !== 'TrainingQuizDetail' &&
      this.$route.name !== 'CustomerContacts' &&
      this.$route.name !== 'CustomerContactDetail' &&
      !this.$route.path.includes('invoices') &&
      !this.$route.path.includes('documents') &&
      !this.$route.path.includes('web-radios') &&
      !hideSideNav
     "
     class="hidden side_nav_off_width navbar border-gray-100 overflow-y-auto lg:block border-r border-t border-gray-300"
    >
     <SideNavOff @showSideNav="showSideNav = true" />
    </aside>
    <main class="flex-1 overflow-y-hidden">
     <!-- Primary column -->
     <section
      aria-labelledby="primary-heading"
      :class="[
       this.$route.name !== 'Login' &&
       this.$route.name !== 'TrainingQuizDetail' &&
       this.$route.name !== 'ResetPassword' &&
       this.$route.name !== '404' &&
       this.$route.name !== 'API404' &&
       this.$route.name !== 'GetStarted' &&
       this.$route.name !== 'Unauthorized' &&
       this.$route.name !== 'EnableService' &&
       this.$route.name !== 'SMS' &&
       this.$route.name !== 'CustomerContacts' &&
       this.$route.name !== 'CustomerContactDetail' &&
       !this.$route.path.includes('invoices') &&
       !this.$route.path.includes('documents') &&
       !this.$route.path.includes('profile') &&
       !this.$route.path.includes('changelog') &&
       !this.$route.path.includes('customer-files') &&
       !this.$route.path.includes('web-radios') &&
       showSideNav
        ? 'main__sidenav--on'
        : this.$route.name !== 'Login' &&
          this.$route.name !== 'TrainingQuizDetail' &&
          this.$route.name !== 'ResetPassword' &&
          this.$route.name !== '404' &&
          this.$route.name !== 'API404' &&
          this.$route.name !== 'GetStarted' &&
          this.$route.name !== 'Unauthorized' &&
          this.$route.name !== 'EnableService' &&
          this.$route.name !== 'SMS' &&
          this.$route.name !== 'CustomerContacts' &&
          this.$route.name !== 'CustomerContactDetail' &&
          !this.$route.path.includes('invoices') &&
          !this.$route.path.includes('documents') &&
          !this.$route.path.includes('profile') &&
          !this.$route.path.includes('changelog') &&
          !this.$route.path.includes('customer-files') &&
          !this.$route.path.includes('web-radios') &&
          !showSideNav
        ? 'main__sidenav--off'
        : this.$route.name !== 'Login' &&
          this.$route.name !== '404' &&
          this.$route.name !== 'GetStarted' &&
          this.$route.name !== 'Unauthorized' &&
          this.$route.name !== 'EnableService'
        ? 'main__no_sidenav'
        : '',
       'min-w-0 flex-1 h-full flex flex-col lg:order-last',
      ]"
     >
      <SuccessBanner
       :key="key"
       :msg="successMsg"
       :active="activeBanner"
       @closeBanner="activeBanner = false"
       :isSuccess="isSuccess"
      />

      <router-view
       :isGuestUser="isGuestUser"
       :key="$route.fullPath && hostName"
       :closeTicketDetail="closeTicketDetail"
       :search="search"
       @tickesCount="(...args) => getTicketCount(...args)"
       @setLoading="isLoading = $event"
       @activeBanner="(activeBanner = true), (key = !key)"
       @successMsg="successMsg = $event"
       @success="isSuccess = $event"
       v-model:search="search"
      />
     </section>
    </main>

    <!-- Secondary column (hidden on smaller screens) -->
   </div>
  </div>
 </div>
 <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :is-full-page="true"
  :lock-scroll="true"
  opacity="0.9"
  color="#1e7889"
 />
</template>

<script>
import SuccessBanner from "./components/SuccessBanner.vue";
import axios from "axios";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import AccouncementBanner from "./components/AnnouncementBanner.vue";
import MainHeader from "./components/MainHeader.vue";
import Sidebar from "./components/Sidebar.vue";
import Banner from "./components/Banner.vue";
import SideNav from "./components/SideNav.vue";
import SideNavOff from "./components/SideNavOff.vue";
import { mapGetters, mapState } from "vuex";

const adminToken = localStorage.getItem("adminToken");
const showBanner = localStorage.getItem("showBanner");

const sandbox = localStorage.getItem("sandbox");
const isLocal = localStorage.getItem("isLocal");

const navigationShort = [
 {
  name: "sidebar.tickets.tickets",
  route: "Tickets",
  show: true,
  href: "/tickets",
  current: false,
  icon: "user-headset",
  children: [
   { name: "sidebar.tickets.open", href: "/tickets" },
   { name: "sidebar.tickets.closed", href: "/closed-tickets/1" },
  ],
  hasSideNav: true,
  permissionName: "ticketing",
  module: "ticketing",
  showModule: true,
 },
 {
  name: "sidebar.invoices",
  route: "Invoices",
  show: true,
  href: "/invoices",
  icon: "envelope-open-dollar",
  current: false,
  hasSideNav: false,
  permissionName: "invoicing",
 },
 {
  name: "users",
  route: "CustomerContacts",
  show: true,
  href: "/contacts",
  icon: "users",
  current: false,
  hasSideNav: false,
  permissionName: "contacts",
 },
];
const navigationLong = [
 {
  name: "sidebar.tickets.tickets",
  route: "Tickets",
  show: true,
  href: "/tickets",
  current: false,
  icon: "user-headset",
  children: [
   { name: "sidebar.tickets.open", href: "/tickets" },
   { name: "sidebar.tickets.closed", href: "/closed-tickets/1" },
  ],
  hasSideNav: true,
  permissionName: "ticketing",
  module: "ticketing",
  showModule: true,
 },
 {
  name: "sidebar.invoices",
  route: "Invoices",
  show: true,
  icon: "envelope-open-dollar",
  href: "/invoices",
  current: false,
  hasSideNav: false,
  permissionName: "invoicing",
 },
 {
  name: "users",
  route: "CustomerContacts",
  show: true,
  href: "/contacts",
  icon: "users",
  current: false,
  hasSideNav: false,
  permissionName: "contacts",
 },
 /*  {
  name: "Documents",
  route: "Documents",
  show: true,
  href: "/documents",
  icon: "folder-open",
  current: false,
  hasSideNav: false,
  permissionName: "customers",
 },
 {
  name: "sidebar.collects",
  route: "Folder",
  show: false,
  href: "/customer-files",
  icon: "file-signature",
  current: false,
  hasSideNav: false,
  permissionName: "information-collections",
 }, */
];

const specialNavigation = [
 {
  name: "Expert Stats",
  route: "ExpertStatistics",
  show: false,
  href: "/expert-statistics/queue",
  icon: "chart-pie",
  current: false,
  children: [
   {
    name: "sidebar.subMenu.callQueue",
    href: "/expert-statistics/queue",
   },
   { name: "Extension", href: "/expert-statistics/extension" },
  ],
  hasSideNav: true,
  permissionName: "expert-statistics",
  serviceName: "expert-stats",
 },
 {
  name: "sidebar.specialNavigation.smartRoutings",
  route: "Expert3cx",
  href: "/contacts-routes",
  current: false,
  show: false,
  icon: "code-pull-request",
  children: [
   { name: "sidebar.specialNavigation.contact", href: "/contacts-routes" },
   { name: "Configuration CTI", href: "/cti" },
   { name: "sidebar.specialNavigation.georouting", href: "/georouting" },
   {
    name: "sidebar.specialNavigation.callqueuegroup",
    href: "/callqueues-groups",
   },
   { name: "sidebar.specialNavigation.callqueue", href: "/callqueues" },
  ],
  hasSideNav: true,
  permissionName: "smart-routings",
  serviceName: "smart-routings",
 },
 {
  name: "SMS",
  route: "SMS",
  show: false,
  href: "/sms",
  icon: "sms",
  current: false,
  children: [
   { name: "Recipients", href: "/sms-recipients/list" },
   { name: "Campaign", href: "/sms-campaign/campaign" },
   { name: "configuration", href: "/sms/configuration" },
   // { name: "Single Message", href: "/sms-single-message" },
  ],
  hasSideNav: true,
  permissionName: "sms",
  serviceName: "sms",
 },
 {
  name: "webRadios",
  route: "WebRadios",
  href: "/web-radios",
  icon: "tower-broadcast",
  current: false,
  children: [],
  hasSideNav: false,
  permissionName: "webradios",
 },
 {
  name: "Training",
  show: false,
  href: "/training/my-courses",
  icon: "graduation-cap",
  current: false,
  children: [
   { name: "courses", href: "/training/courses" },
   { name: "myCourses", href: "/training/my-courses" },
   //  { name: "Quizzes", href: "/training/quizzes" },
   // { name: "Single Message", href: "/sms-single-message" },
  ],
  hasSideNav: true,
  module: "training",
 },
];

const trainingNavigation = [
 { name: "courses", href: "/training/courses" },
 { name: "myCourses", href: "/training/my-courses" },
 //  { name: "Quizzes", href: "/training/quizzes" },
];

const statisticsNavigation = [
 { name: "sidebar.subMenu.callQueue", href: "/pbx-callqueue" },
 { name: "Extension", href: "/pbx-extension" },
 { name: "Top 20", href: "/top-20-statistics" },
];

const expertStatisticsNavigation = [
 {
  name: "sidebar.subMenu.callQueue",
  href: "",
  children: [
   // {
   //   name: "expertStatisticsNavigation.advancedStatistics",
   //   href: "",
   // },
   {
    name: "expertStatisticsNavigation.origins",
    href: "/expert-statistics/queue",
   },
   {
    name: "expertStatisticsNavigation.originsTop10",
    href: "/expert-statistics-origin-detail/queue",
   },
   {
    name: "expertStatisticsNavigation.answeredUnansweredCalls",
    href: "/expert-statistics-answered-unanswered/queue",
   },
   //  {
   //   name: "expertStatisticsNavigation.answeredUnansweredCallsWeekDay",
   //   href: "/expert-statistics-answered-unanswered/week-day/queue",
   //  },
   //  {
   //     name: "expertStatisticsNavigation.answeredUnansweredCallsWeekYear",
   //   href: "/expert-statistics-answered-unanswered/week-year/queue",
   //  },
   {
    name: "details",
    href: "/expert-statistics/full-queue-report",
   },
   //  {
   //   name: "waitingTime",
   //   href: "/expert-statistics-call-durations/waiting-time/queue",
   //  },
   {
    name: "durations",
    href: "/expert-statistics-call-durations/queue",
   },
   {
    name: "queueComparison",
    href: "/expert-statistics/compare-queues",
   },
   {
    name: "didReport",
    href: "/expert-statistics/did-report",
   },
   {
    name: "didCallers",
    href: "/expert-statistics/did",
   },
   {
    name: "expertStatisticsNavigation.callerNumbers",
    href: "/expert-statistics/caller-numbers",
    beta: true,
   },
   {
    name: "expertStatisticsNavigation.groups",
    href: "/expert-statistics/groups/queue",
   },
   // {
   //   name: "expertStatisticsNavigation.hourlyStatistics",
   //   href: "",
   // },
   {
    name: "sidebar.specialNavigation.reportList",
    href: "/expert-statistics/reports/queue",
   },
  ],
 },
 {
  name: "Extension",
  href: "",
  children: [
   // {
   //   name: "expertStatisticsNavigation.advancedStatistics",
   //   href: "",
   // },
   {
    name: "expertStatisticsNavigation.origins",
    href: "/expert-statistics/extension",
   },
   {
    name: "expertStatisticsNavigation.originsTop10",
    href: "/expert-statistics-origin-detail/extension",
   },
   {
    name: "expertStatisticsNavigation.answeredUnansweredCalls",
    href: "/expert-statistics-answered-unanswered/extension",
   },
   // {
   //  name: "waitingTime",
   //  href: "/expert-statistics-call-durations/extension",
   // },
   {
    name: "durations",
    href: "/expert-statistics-call-durations/extension",
   },
   {
    name: "expertStatisticsNavigation.groups",
    href: "/expert-statistics/groups/extension",
   },
   // {
   //   name: "expertStatisticsNavigation.hourlyStatistics",
   //   href: "",
   // },
   {
    name: "sidebar.specialNavigation.reportList",
    href: "/expert-statistics/reports/extension",
   },
  ],
 },
 //  {
 //   name: "outgoing",
 //   href: "",
 //   children: [
 //    {
 //     name: "expertStatisticsNavigation.overview",
 //     href: "/expert-statistics-outbound/extension",
 //    },
 //    {
 //     name: "expertStatisticsNavigation.answeredUnansweredCalls",
 //     href: "/expert-statistics-outbound/answered/extension",
 //    },
 //    {
 //     name: "expertStatisticsNavigation.durations",
 //     href: "/expert-statistics-outbound/duration/extension",
 //    },
 //    {
 //     name: "expertStatisticsNavigation.pbx",
 //     href: "/expert-statistics-outbound/pbx",
 //    },
 //    {
 //     name: "expertStatisticsNavigation.trunk",
 //     href: "/expert-statistics-outbound/trunk",
 //    },
 //   ],
 //  },
];

const expertNavigation = [
 { name: "sidebar.specialNavigation.contact", href: "/contacts-routes" },
 { name: "Configuration CTI", href: "/cti" },
 { name: "sidebar.specialNavigation.georouting", href: "/georouting" },
 {
  name: "sidebar.specialNavigation.callqueuegroup",
  href: "/callqueues-groups",
 },
 { name: "sidebar.specialNavigation.callqueue", href: "/callqueues" },
];

const smsNavigation = [
 // { name: "SMS List", href: "/sms" },
 { name: "Messages", href: "/sms-campaign/campaign" },
 { name: "Recipients", href: "/sms-recipients/list" },
 { name: "configuration", href: "/sms/configuration" },
 // { name: "Single Message", href: "/sms-single-message" },
];

const ticketNavigation = [
 { name: "sidebar.tickets.open", href: "/tickets", number: 0 },

 {
  name: "sidebar.tickets.closed",
  href: "/closed-tickets",
  number: 0,
 },
];

const surveyNavigation = [
 { name: "sidebar.survey.replies", href: "/reply-surveys/1" },
 { name: "sidebar.survey.all", href: "/all-surveys/1" },
];

const configurationNavigation = [
 {
  name: "sidebar.tickets.tickets",
  href: "/support/tickets-categories",
  children: [
   { name: "sidebar.tickets.templates", href: "/support/tickets-templates" },
   {
    name: "sidebar.support.ticketCategories",
    href: "/support/tickets-categories",
   },
   {
    name: "sidebar.support.ticketStatuses",
    href: "/support/tickets-statuses",
   },
   { name: "sidebar.support.emailFilters", href: "/support/email-filters" },
  ],
 },
 {
  name: "sidebar.hosts.hosting",
  href: "/configuration/hosts",
  children: [
   {
    name: "sidebar.hosts.operatingSystems",
    href: "/configuration/hosting/hostOs",
   },
   {
    name: "sidebar.hosts.software",
    href: "/configuration/hosting/hostSoftwares",
   },
   { name: "sidebar.hosts.roles", href: "/configuration/hosting/hostRoles" },
   { name: "sidebar.hosts.types", href: "/configuration/hosting/hostTypes" },
   {
    name: "sidebar.hosts.shells",
    href: "/configuration/hosting/hostShells",
   },
   {
    name: "sidebar.hosts.functions",
    href: "/configuration/hosting/hostFunctions",
   },
   {
    name: "sidebar.hosts.supliers",
    href: "/configuration/hosting/hostSuppliers",
   },
   {
    name: "sidebar.hosts.datacenter",
    href: "/configuration/hosting/datacenters",
   },
   {
    name: "sidebar.hosts.hostServices",
    href: "/configuration/hosting/hostServices",
   },
  ],
 },
];

const secondaryNavigation = [];

const showSpecialNavigation = true;

const statisticsAvailableForProduction = process.env.VUE_APP_STATISTICS_PRODUCTION_AVAILABLE;
const account = localStorage.getItem("account");

export default {
 title() {
  return `${localStorage.getItem("title")}`;
 },
 components: {
  AccouncementBanner,
  MainHeader,
  Sidebar,
  Banner,
  SideNav,
  SideNavOff,
  Loading,
  SuccessBanner,
 },
 data() {
  return {
   statisticsNavigation,
   expertStatisticsNavigation,
   expertNavigation,
   smsNavigation,
   navigationShort,
   navigationLong,
   secondaryNavigation,
   specialNavigation,
   showSpecialNavigation,
   configurationNavigation,
   surveyNavigation,
   ticketNavigation,
   statisticsAvailableForProduction,
   sandbox,
   isLocal,
   cssPath: "",
   openFeedback: false,
   openSide: false,
   openSubmenu: false,
   showSideNav: true,
   hasSideNav: false,
   sideNavHover: false,
   sideHoverNav: "",
   hideNoNav: false,
   adminToken,
   showBanner,
   closeBannerKey: false,
   closeTicketDetail: false,
   isGuestUser: false,
   hostsNames: [],
   isLoading: true,
   search: "",
   hideSideNav: false,
   trainingNavigation,
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
   account,
   hasDocuments: false,
  };
 },
 methods: {
  initIcon(icon) {
   var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
   link.type = "image/x-icon";
   link.rel = "shortcut icon";
   link.href = icon;
   document.getElementsByTagName("head")[0].appendChild(link);
  },
  initTitle(title) {
   document.title = title;
  },
  async getPbxHosts() {
   if (
    (this.userPermissions.permissions?.includes("pbx-hosts.*") ||
     this.userPermissions.permissions?.includes("pbx-hosts.view")) &&
    this.token &&
    this.account !== "ADMIN"
   ) {
    try {
     const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/pbxHosts`, {
      params: { customerAccount: this.account },
      headers: {
       Authorization: `Bearer ${this.token}`,
      },
     });
     if (res.data && res.data.length > 0) {
      this.$store.dispatch("hostNames", res.data);
      if (!this.hostName || this.hostName === "null" || this.hostName !== res.data[0]) {
       this.$store.dispatch("hostName", res.data[0]);
       localStorage.setItem("hostName", res.data[0]);
      }
     }
     this.hostsNames = res.data;
    } catch (error) {
     this.errorHandling(error);
     // this.isLoading = false;
    }
    // //this.isLoading = false;
   }
  },
  openSideNavHover(event) {
   this.sideHoverNav = event;
   if (
    this.sideHoverNav === "sidebar.invoices" ||
    this.sideHoverNav === "sidebar.tickets" ||
    this.sideHoverNav === "Documents"
   ) {
    this.sideNavHover = false;
    this.showSideNav = true;
   } else {
    this.sideNavHover = true;
   }
   this.hideNoNav = true;
  },
  async getApi() {
   try {
    axios
     .post("https://air-traffic.bluerocktel.net/api/flights")
     .then((res) => {
      // console.log(res.data[0]);
      let apiData = {};
      if (Object.keys(res.data[0]).length > 1) {
       apiData = res.data[0].encom_clients_sandbox;
       //apiData = res.data[0].encom_clients_production;
       //apiData = res.data[0]["0535"];
       localStorage.setItem("isLocal", true);
      } else {
       apiData = res.data[0].default;
      }
      // console.log(apiData);
      if (apiData && apiData.active) {
       if (apiData.baseUrl.includes("https://telecom0580-clients.bluerocktel.net")) {
        for (let index = 0; index < this.navigation.length; index++) {
         if (this.navigation[index].href.includes("/invoices")) this.navigation[index].show = false;
        }
       }
       this.initIcon(apiData.logo);
       this.initTitle(apiData.title);
       localStorage.setItem("sandbox", apiData.sandbox);
       localStorage.setItem("logo", apiData.logo);
       this.$store.dispatch("logo", apiData.logo);
       localStorage.setItem("title", apiData.title);
       localStorage.setItem("background", apiData.background);
       this.$store.dispatch("background", apiData.background);
       this.$store.dispatch("baseUrl", apiData.baseUrl);
       if (!localStorage.getItem("language")) {
        localStorage.setItem("language", apiData.lang);
       }
       if (apiData.demo) {
        this.isGuestUser = true;
        this.$store.dispatch("isGuest", true);
        localStorage.setItem("sandbox", true);
       }
       if (!this.$cookie.getCookie("API") || this.$cookie.getCookie("API") !== apiData.baseUrl) {
        localStorage.removeItem("token");
        localStorage.removeItem("adminToken");
        localStorage.removeItem("account");
        this.$store.dispatch("token", null);
        this.$store.dispatch("adminToken", null);
        this.$router.push("/login");
        this.$cookie.setCookie("API", apiData.baseUrl, {
         expire: "21d",
        });
       }
      }
     })
     .then(this.getPbxHosts())
     .catch((err) => {
      if (err.response.status == 404) {
       axios.post("https://air-traffic.bluerocktel.net/api/flight-to").then((res) => {
        let apiData = {};
        apiData = res.data;
        if (apiData && apiData.active) {
         if (apiData.baseUrl.includes("https://telecom0580-clients.bluerocktel.net")) {
          for (let index = 0; index < this.navigation.length; index++) {
           if (this.navigation[index].href.includes("/invoices"))
            this.navigation[index].show = false;
          }
         }

         this.initIcon(apiData.logo);
         this.initTitle(apiData.title);
         localStorage.setItem("sandbox", apiData.sandbox);
         localStorage.setItem("logo", apiData.logo);
         this.$store.dispatch("logo", apiData.logo);
         localStorage.setItem("title", apiData.title);
         localStorage.setItem("background", apiData.background);
         this.$store.dispatch("background", apiData.background);
         if (!localStorage.getItem("language")) {
          localStorage.setItem("language", apiData.lang);
         }
         if (apiData.demo) {
          this.isGuestUser = true;
          this.$store.dispatch("isGuest", true);
          localStorage.setItem("sandbox", true);
         }
         if (!this.$cookie.getCookie("API") || this.$cookie.getCookie("API") !== apiData.baseUrl) {
          localStorage.removeItem("token");
          localStorage.removeItem("adminToken");
          localStorage.removeItem("account");
          this.$store.dispatch("token", null);
          this.$store.dispatch("adminToken", null);
          this.$router.push("/login");
          this.$cookie.setCookie("API", apiData.baseUrl, {
           expire: "21d",
          });
         }
        }
       });
      }
     })
     .catch((err) => {
      if (err.response.status == 404) {
       this.$router.push("/api-not-found");
      }
     });
   } catch (error) {
    this.errorHandling(error);
   }
  },
  getIP() {
   axios.get("http://ip-api.com/json").then((x) => console.log(x));
  },
  getTicketCount(...args) {
   let open = args[0];
   let closed = args[1];
   this.ticketNavigation[0].number = open;
   this.ticketNavigation[1].number = closed;
  },
  async getModules() {
   try {
    const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/modules`, {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    });
    this.modules = res.data;

    const data = JSON.stringify(res.data);
    localStorage.setItem("modules", data);
    //this.isLoading = false;
   } catch (error) {
    //this.isLoading = false;
    console.error(error);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   }
  },

  async getMyPermissions(account) {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/auth/my-permissions?customerAccount=${account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    this.userPermissions = res.data;
    const data = JSON.stringify(res.data);
    localStorage.setItem("userPermissions", data);
   } catch (error) {
    console.error(error);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   } finally {
    this.getApi();
   }
  },
  async getDocuments() {
   let id = this.account.slice(2, this.account.length);
   this.$emit("setLoading", true);
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/documents?documentable_type=App\\Customer&documentable_id=${id}&customerAccount=${
      this.account
     }`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );

    if (res.data.length > 0) {
     this.navigationLong.push({
      name: "Documents",
      route: "Documents",
      show: true,
      href: "/documents",
      icon: "folder-open",
      current: false,
      hasSideNav: false,
      permissionName: "customers",
     });
    }
   } catch (error) {
    console.error(error);
   } finally {
    this.$emit("setLoading", false);
   }
  },
  async getInformationCollections() {
   this.$emit("setLoading", true);

   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/informationCollections?customerAccount=${
      this.account
     }`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );

    if (res.data.data.length > 0) {
     this.navigationLong.push({
      name: "sidebar.collects",
      route: "Folder",
      show: true,
      href: "/customer-files",
      icon: "file-signature",
      current: false,
      hasSideNav: false,
      permissionName: "information-collections",
     });
    }
   } catch (error) {
    console.error(error.response);
    this.$emit("setLoading", false);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   }
  },
 },
 created() {
  this.getApi();
  if (this.account && this.account !== "ADMIN") {
   this.getDocuments();
   this.getInformationCollections();
   this.getModules().then(() => {
    this.navigationLong.forEach((item) => {
     const show = item.module ? this.modules[item.module] : null;
     item.showModule = show ? show : true;
    });
   });

   //this.getMyPermissions(this.account);
  }
 },
 computed: {
  ...mapGetters(["account", "hostName", "token", "isLoading", "modules"]),
  ...mapState({
   userPermissions: (state) => state.userPermissions,
   services: (state) => state.services,
  }),
  updatedSpecialNavigation() {
   return this.specialNavigation.map((item) => {
    const objService = this.services.find((service) => service.key === item.serviceName);
    if (objService) {
     const { enabled, can_be_enabled } = objService;
     item.show = enabled || (!enabled && can_be_enabled);
    }
    return item;
   });
  },
 },
};
</script>

<style>
.side_nav_width {
 min-width: 15rem;
 overflow: hidden;
 position: fixed;
 min-height: 96%;
 top: 3rem;
 left: 6rem;
 z-index: 10;
}
.side_nav_off_width {
 width: 50px;
 overflow: hidden;
 position: fixed;
 min-height: 96%;
 top: 3rem;
 left: 6rem;
 z-index: 10;
}
.main__sidenav--on {
 margin-left: 20rem;
 margin-top: 3rem;
}
.main__sidenav--off {
 margin-left: 8rem;
 margin-top: 3rem;
}
.main__no_sidenav {
 margin-left: 5rem;
 margin-top: 3rem;
}
html {
 scroll-behavior: smooth;
}
</style>
