<template>
 <div v-if="navigation" class="flex flex-col flex-grow pt-5 pb-4 navbar overflow-y-auto">
  <div class="flex justify-end pb-1.5">
   <div class="flex justify-end">
    <button
     class="p-2 hover:bg-gray-100 rounded-lg mr-1 navbar__text"
     @click="$emit('showSideNav')"
    >
     <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
     >
      <path
       fill-rule="evenodd"
       d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
       clip-rule="evenodd"
      />
     </svg>

     <!-- <svg
             v-show="isHover"
             xmlns="http://www.w3.org/2000/svg"
             class="h-4 w-4"
             viewBox="0 0 20 20"
             fill="currentColor"
           >
             <path
               fill-rule="evenodd"
               d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
               clip-rule="evenodd"
             />
           </svg> -->
     <!-- <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-5 w-5"
           viewBox="0 0 20 20"
           fill="currentColor"
         >
           <path
             fill-rule="evenodd"
             d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
             clip-rule="evenodd"
           />
         </svg> -->
    </button>
   </div>
  </div>
  <div class="mt-6 flex-grow flex flex-col">
   <nav class="flex-1 px-2 space-y-1 navbar navbar__text" aria-label="Sidebar">
    <template v-for="item in navigation" :key="item.name">
     <div v-if="!item.children">
      <router-link
       @click="$emit('deactiveHover')"
       :to="item.href"
       :class="[
        item.href === this.$route.path ? 'navbar__current' : 'navbar navbar__hover',
        item.number == 0 && item.href !== this.$route.path ? 'navbar__ligh-text' : 'navbar__text',
        'group w-full flex items-center pl-7 pr-2 py-2 text-xs font-medium rounded-md justify-between',
       ]"
      >
       {{ $t(`${item.name}`) }}
       <span v-if="item.number >= 0">{{ item.number }}</span>
      </router-link>
     </div>
     <Disclosure as="div" v-else class="space-y-1" v-slot="{ open }">
      <DisclosureButton
       :class="[
        item.href === this.$route.path ? 'navbar__current' : 'navbar navbar__hover navbar__text',
        'group w-full flex items-center pr-2 py-2 text-left text-sm font-medium rounded-md focus:outline-none',
       ]"
      >
       <svg
        :class="[
         open ? 'text-gray-400 rotate-90' : 'text-gray-300',
         'mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
        ]"
        viewBox="0 0 20 20"
        aria-hidden="true"
       >
        <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
       </svg>
       {{ $t(`${item.name}`) }}
      </DisclosureButton>
      <DisclosurePanel class="space-y-1">
       <router-link
        @click="$emit('deactiveHover')"
        v-for="subItem in item.children"
        :key="subItem.name"
        :to="subItem.href"
        :class="[
         subItem.href === this.$route.path
          ? 'navbar__current'
          : 'navbar navbar__hover navbar__text',
         'group w-full flex items-center pl-8 py-2 text-left text-xs font-medium rounded-md focus:outline-none',
        ]"
       >
        {{ $t(subItem.name) }}

        <span
         v-show="subItem.beta"
         class="ml-1 inline-flex items-center rounded-md bg-yellow-50 px-1.5 py-0.5 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-700 ring-opacity-20"
        >
         <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="w-3 h-3 mr-1"
         >
          <path
           d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z"
          />
         </svg>
         {{ $t("beta") }}
        </span>

        <span v-if="subItem.number >= 0">{{ subItem.number }}</span>
       </router-link>
      </DisclosurePanel>
     </Disclosure>
    </template>
   </nav>
  </div>
 </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

export default {
 props: ["navigation", "isHover"],
 components: {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
 },
 data() {
  return {
   showMsg: false,
  };
 },
 methods: {
  hasNavigation() {
   if (!this.navigation) {
    this.$emit("hasNavigation", false);
   } else {
    this.$emit("hasNavigation", true);
   }
  },
 },
 mounted() {
  this.hasNavigation();
 },
 watch: {
  navigation: function () {
   this.hasNavigation();
  },
 },
};
</script>

<style></style>
