export default {
 data() {
  return {
   time: "",
  };
 },
 methods: {
  timeFormater(time) {
   // if (time > 3600) {
   //   return new Date(time * 1000).toISOString().slice(11, 19);
   // } else {
   return new Date(time * 1000).toISOString().slice(11, 19);
   // }
  },
  toLocaleDateString(date) {
   const dateCreated = new Date(date);

   const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
   };
   return dateCreated.toLocaleDateString("fr-FR", options);
  },
  toLocaleTimeString(time) {
   const dateCreated = new Date(time);
   const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
   };
   return dateCreated.toLocaleTimeString("fr-FR", options);
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear() +
    " " +
    this.$t("at") +
    " " +
    ("0" + dateCreated.getHours()).slice(-2) +
    ":" +
    ("0" + dateCreated.getMinutes()).slice(-2);

   return formatedDate;
  },
  formatDateFrench(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear() +
    " à " +
    ("0" + dateCreated.getHours()).slice(-2) +
    ":" +
    ("0" + dateCreated.getMinutes()).slice(-2);

   return formatedDate;
  },
  formatSecsToMinAndSecs(value) {
   if (value && value > 0) {
    return new Date(value * 1000).toISOString().substring(11, 19);
   }
   var minutes = Math.floor(value / 60).toFixed(0);
   var seconds = (value - minutes * 60).toFixed(0);
   return minutes + "(min)" + ":" + seconds + "(s)";
  },
  formatSecsToHourMinAndSecs(value) {
   let formated = undefined;
   if (value > 86400) {
    let quotient = Math.floor(value / 86400);
    let remainder = value % 86400;
    formated = quotient + "." + new Date(remainder * 1000).toISOString().substring(11, 19);
   } else {
    formated = new Date(value * 1000).toISOString().substring(11, 19);
   }
   return formated;
  },
 },
};
