<template>
 <div class="tooltip" :class="{ 'show-tooltip': show }">
  <slot></slot>
  <div class="tooltip-content capitalize">{{ content }}</div>
 </div>
</template>

<script>
export default {
 props: {
  show: {
   type: Boolean,
   required: true,
  },
  content: {
   type: String,
   required: true,
  },
 },
 data() {
  return {};
 },
};
</script>

<style scoped>
.tooltip {
 position: relative;
 display: inline-block;
}

.tooltip-content {
 position: absolute;
 top: -40px;
 left: 50%;
 transform: translateX(-50%);
 background-color: rgb(63 63 70);
 color: rgb(248 250 252);
 padding: 2px 8px;
 border-radius: 0.375rem;
 box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 font-size: 13px;
 opacity: 0;
 visibility: hidden;
 transition: opacity 0.2s ease, visibility 0.2s ease;
}

.tooltip-content::before {
 content: "";
 position: absolute;
 top: 100%;
 left: 50%;
 transform: translateX(-50%);
 border-style: solid;
 border-width: 8px 8px 0 8px;
 border-color: rgb(63 63 70) transparent transparent transparent;
}

.show-tooltip .tooltip-content {
 opacity: 1;
 visibility: visible;
}
</style>
